<template>
<div class="list-roles-wrap">
    <CRow>
      <CCol class="col--padding">
        <CCard>
          <CCardBody>
            <div class="flex1">
                <label>{{$t("LIST_ROLES_PAGE_LABEL_ROLE_MANAGEMENT")}}</label>
                <CButton class="btn btn-success btn-md btn-new" @click="goAdd">
                    + {{$t('LIST_ROLES_PAGE_BUTTON_ADD_ROLE')}}
                </CButton>
            </div>
            <table class="table table-striped table-drag">
              <thead class="thead-white">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{$t("LIST_ROLES_PAGE_LABEL_NAME")}}</th>
                        <th scope="col">{{$t("LIST_ROLES_PAGE_LABEL_LEVEL")}}</th>
                        <th scope="col" class="text-center">{{$t("LIST_ROLES_PAGE_LABEL_ACTION")}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(role, index) in roles" :key="role.id">
                        <td>{{index+1}}</td>
                        <td>{{role.name}}</td>
                        <td>{{role.level}}</td>
                        <td class="text-center">
                          <CButton @click="edit(role.id)" :disabled="role.level===0 ? true : false">
                            {{$t("LIST_ROLES_PAGE_BUTTON_EDIT")}}
                          </CButton>
                        </td>
                    </tr>
                </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
</div>
</template>

<script>
import endpoints from '@/constants/endpoints';
import Draggable from "vuedraggable";
import { mapState, mapActions } from "vuex";
export default {
    components: {
        Draggable
    },
    data(){
        return{
            roles: [],
            limit: 10000,
            activePage:1,
            pageInput:1,
            total:null,
        }
    },
    async mounted(){
        const role = await this.$http.get(endpoints.getRoles);
        this.roles = role.data;
    },
    methods: {
      ...mapActions(['getSelectedRole']),
      edit(id) {
        const roleId = id;
        let role = this.roles.filter(role => role.id === roleId)[0];
        this.getSelectedRole(role);
        this.$router.push(`roles/edit-roles/${roleId}`);
      },
      goAdd(){
        this.$router.push(`roles/add-roles`);
      }
    },
    name: 'ListRoles'
}
</script>

<style lang="scss">
.list-roles-wrap{
  $green: #4dc1a1;
  @media screen and ( max-width: 1024px ){
    padding: 0 15px;
  }
  .row{
        padding: 0;
        margin: 0;
        .col--padding{
            padding: 0 24px;
        }
    }
  .card-body{
    padding-top: 10px;
    padding-bottom: 10px;
    .flex1{
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      @media screen and ( max-width: 1024px ){
        flex-wrap: wrap;
        justify-content: center;
      }
      label{
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0;
      }
      .btn-new {
        padding: 0;
        width: 170px;
        height: 32px;
        border-radius: 32px;
        background: #3B4B7E;
        border-color: unset;
        .pr--plus{
          svg{
            margin-top: 0;
          }
        }
      }
    }
    
    .table{
        border: 1px solid #d8dbe0;
      tr{
        color: #657186;
        // font-family: "SF UI Display";
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      thead{
        tr{
          font-weight: 600;
        }
      }
      td{
          color: #4D5666;
          font-size: 14px;
          line-height: 41px;
          vertical-align: inherit;
          /*font-weight: 300;*/
      }
      .blue{
        button{
          color: #5F55E7;
          font-size: 14px;
          line-height: 41px;
        }
      }
      .texts-wrap{
        display: flex;
        flex-direction: column;
        button{
          line-height: 10px;
        }
        span{
          background: #f2f52d;
          color: #000000;
          border-radius: 5px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    // .pagination--user{
    //   .pagination{
    //     margin-bottom: 5px;
    //     .page-item{
    //       &:last-child,
    //       &:first-child{
    //         width: 34px;
    //         height: 35px;
    //       }
    //       &:not(:last-child):not(:first-child){
    //         width: 32px;
    //         height: 35px;
    //       }
    //     }
    //   }
    // }
  }
}
.text-center{
    text-align: center;
}
</style>